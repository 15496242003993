import React from "react";
import Main from "layouts/Main";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";

import {
  BigTextHeader,
  CurrentPageNavText,
  ImagePlusText,
  OurServices,
  BannerWithButton,
  RecentWork,
} from "customComponents";

import Development001 from "customComponents/ImagePlusText/Development001.png";
import Development002 from "customComponents/ImagePlusText/Development002.png";

const services = [
  {
    title: "Website",
    description:
      "Whether it's a new CMS, a new language, or a new framework, we're always looking for ways to better serve our clients and their needs.",
    avatar: "/images/avatars/WebMobileDevlopment.png",
  },
  {
    title: "Mobile App",
    description:
      "We're the experts in mobile development. We bring you the best of both worlds: a strong technical foundation, and a focus on user experience.",
    avatar: "/images/avatars/MobileApp.png",
  },
  {
    title: "Back End",
    description:
      "We're a team of backend developers who are passionate about building robust, secure, and scalable systems. We work with a variety of technologies, including Node.js, Python, and Java.",
    avatar: "/images/avatars/BackEnd.png",
  },
  {
    title: "CMS",
    description:
      "We specialize in custom CMS development and are experts at making sure your site performs exactly as you need it to.",
    avatar: "/images/avatars/CMS.png",
  },
];

const managedServices = [
  {
    title: "Frontend Development",
    description:
      "If you're looking for React JS, VueJS, NextJS, GatsbyJS—or any other similar technology—we've got you covered!",
    avatar: "/images/avatars/FrontendDevelopment.png",
  },
  {
    title: "Backend Development",
    description:
      "Our team of developers can work with all the latest technologies of backend development—AWS, Azure, Ruby on Rails, and PHP etc.",
    avatar: "/images/avatars/BackendDevelopment.png",
  },
  {
    title: "Full Stack Development",
    description:
      "Our team is comprised of designers, developers, and other technologists who specialize in the latest tech stacks like MEAN, MERN, LAMP, and Ruby on Rails.",
    avatar: "/images/avatars/FullStackDevelopment.png",
  },
];

const Web = ({ pageContext }) => {
  const { city } = pageContext;
  const developmentTitleHTML = <div>What We Can Do For You</div>;
  const developmentMainHTML = (
    <div>
      We can build you an app that meets your unique needs—no matter how
      technical or complex they are. Whether you need a simple app to help your
      customers book appointments or a complex one that can handle multiple
      languages and currencies, we're ready to help.
      <br />
      <br />
      We have extensive experience working with clients on both sides of the
      industry: businesses who want to develop their own apps, and developers
      who want us to build their app for them. Our team of developers and
      designers has worked with companies large and small, from startups to
      Fortune 100 companies, so whatever your project is, we've got you covered.
    </div>
  );

  const digitalStandOutTitleHTML = (
    <div>
      Become A Digital Standout
      <br />
      With A Beautiful Website
    </div>
  );
  const digitalStandOutMainHTML = (
    <div>
      Our websites standout because:
      <br />
      <ul>
        <li>Custom and uniquely designed to reflect YOU and your business</li>
        <li>
          Built with latest technology to make your website look great on all
          devices
        </li>
        <li>
          Our 12 months FREE maintenance plan will keep your site up-to-date and
          secure
        </li>
        <li>
          Our customer service team is available to help you out with any issues
          or questions
        </li>
      </ul>
    </div>
  );

  const managedServiceTitleHTML = (
    <div>
      A Managed Service For All
      <br />
      Your Digital Needs
    </div>
  );
  const managedServiceMainHTML = (
    <div>
      Whether you're looking for front-end, back-end, or full-stack managed
      services, Sonnul will help you take your business to the next level. We're
      proud to offer a wide range of digital marketing services that are
      tailored to your needs—we work with businesses of all sizes and industries
      to create custom solutions that meet your unique goals.
    </div>
  );

  return (
    <Main colorInvert={true}>
      <Helmet>
        <title>{city.title}</title>
        <meta name="description" content={city.description} />
        <meta property="og:title" content={city.title} />
        <meta property="og:description" content={city.description} />
        <meta property="og:image" content={city.image} />
        <meta
          property="og:url"
          content={`https://www.sonnul.com/web/${city.slug}`}
        />
        <meta name="twitter:title" content={city.title} />
        <meta name="twitter:description" content={city.description} />
        <meta name="twitter:image" content={city.image} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <BigTextHeader
        headerText={city.headerText}
        subheaderText={"Free Consultation"}
        buttonText={"Schedule Call"}
        showButton={true}
        buttonLink="/schedule-call"
      />
      <CurrentPageNavText navLocText={"Home > Website Development"} />

      <ImagePlusText
        titleVariant={"h3"}
        title={developmentTitleHTML}
        mainText={developmentMainHTML}
        imageSrc={Development001}
        showButton={false}
        fullScreenFlexDirection={"row"}
        smallScreenFlexDirection={"column"}
        imageSizeLarge={420}
        imageSizeSmall={350}
        yPaddingLarge={15}
        yPaddingSmall={4}
      />

      <OurServices
        title="Achieve Your Business Goals With Web Development Solutions."
        showButton={false}
        services={services}
        noBoxBackground={false}
      />
      <BannerWithButton
        text1="Schedule a quick call with our team."
        text2="We're excited to see what you have in mind!"
        buttonText="Schedule Call"
        buttonAltText="Free Consultation"
        linkUrl="/schedule-call"
      />

      <ImagePlusText
        titleVariant={"h3"}
        title={digitalStandOutTitleHTML}
        mainText={digitalStandOutMainHTML}
        imageSrc={Development002}
        showButton={true}
        fullScreenFlexDirection={"row-reverse"}
        smallScreenFlexDirection={"column"}
        imageSizeLarge={420}
        imageSizeSmall={350}
        yPaddingLarge={15}
        yPaddingSmall={4}
        btnText={"GET IN TOUCH"}
        btnLink={"/contact"}
      />

      <Box bgcolor={"background.paper2"} paddingY={6}>
        <ImagePlusText
          titleVariant={"h3"}
          title={managedServiceTitleHTML}
          mainText={managedServiceMainHTML}
          services={managedServices}
          showButton={false}
          fullScreenFlexDirection={"row"}
          smallScreenFlexDirection={"column"}
          imageSizeLarge={420}
          imageSizeSmall={350}
          yPaddingLarge={6}
          yPaddingSmall={4}
        />
      </Box>

      {/* <RecentWork /> */}
      <BannerWithButton
        text1="Schedule a quick call with our team."
        text2="We're excited to see what you have in mind!"
        buttonText="Schedule Call"
        buttonAltText="Free Consultation"
        linkUrl="/schedule-call"
      />
    </Main>
  );
};

export default Web;
